.wrapper {
	display: block;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;
	padding: 4px 8px;
	border-radius: 8px;
	border: 1px solid transparent;
	box-shadow: none;

	transition: all 250ms ease-in-out;
}

.image {
	width: 30px;
	height: 30px;
}

.button:hover {
	border-color: var(--color-orange-faded) !important;
}
