.wrapper {
  display: flex;
  flex-direction: column;
}

.buttonsWrapper {
  max-width: 250px;
  flex-direction: row;
}

@media screen and (min-width: 500px) {
  .wrapper {
    flex-direction: row;
  }

  .buttonsWrapper {
    max-width: unset;
    flex-direction: column;
    justify-content: flex-start;
  }
}
