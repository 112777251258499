.title {
	color: var(--color-blue-primary);
	text-decoration: underline var(--color-orange-primary);
	margin: 0;
	margin-bottom: 20px;
	text-align: left;
}

.buttonLinksWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 70%;
	min-width: 300px;
	gap: 60px;

	margin: 0 auto;

	list-style: none;
}

.link {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	border-radius: 24px;

	overflow: hidden;

	width: calc(50% - 60px);
	min-width: 280px;
	max-width: 280px;

	height: 150px;
	text-decoration: none;
}

.imageWrapper {
	width: 52%;
	height: 100%;
	overflow: hidden;
	/* min-width: 200px; */
}

.image {
	display: block;
	width: 100%;
	height: 100%;
	/* min-width: 200px; */
	/* aspect-ratio: 9 / 16; */
	object-fit: cover;
}

.textWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60%;
	padding: 0 1rem;
	text-align: center;
}

.text {
	text-decoration: none !important;
	font-size: 1.5rem;
	color: black;
	font-weight: 500;
	max-width: 125px;
}

.iconTextItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.link:hover {
	text-decoration: underline;
}

.tabTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;

	/* margin-top: 0.75rem; */
	margin: 0.5rem 0 0.5rem !important;
	text-align: left;
	font-size: 18px !important;

	color: var(--color-deep-navy) !important;
}

.underline {
	text-decoration: underline var(--color-orange-light) !important;
}

/* :global(.ant-drawer-header-title) {
	flex-direction: row-reverse;
  }
  
  :global(.ant-drawer-body) {
	padding: 8px 8px !important;
  }
   */
.eyeHover,
.starHover {
	color: gray; /* Default color */
}

.starHoverActive {
	color: #04bbeb;
}

.eyeHover:not(:disabled):hover,
.starHover:not(:disabled):hover,
.starHoverActive:not(:disabled):hover {
	color: #fc941c !important;
	border-color: #fc941c !important;
}

.copied:disabled {
	cursor: default !important;
}

.logo {
	width: 100px;
	display: block;
	/* width: fit-content; */
}

.logo img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.headerTitle {
	/* max-width: 130px; */
	max-width: 160px;
	text-align: center !important;

	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--color-deep-navy);
	font-size: 1.25rem;
	font-weight: 400;
	margin: 0 auto;
}

.analyzeButton {
	background-color: var(--color-orange-faded);
	color: #fff;
	font-weight: 600;
	font-size: 14px !important;
	/* padding-left: 2rem;
	padding-right: 2rem; */
	border: none !important;
	/* pointer-events: none; */
	/* cursor: wait; */

	/* min-width: 300px; */
	/* z-index: 30; */
}

/* .viewAnalysisButton {
	font-weight: 600;
	font-size: 14px !important;
  } */

.analyzeButton:not(:disabled):hover {
	background-color: var(--color-orange-secondary) !important;
	color: #fff !important;
	border: none !important;
}

.analyzeButton:disabled {
	cursor: progress;
	color: rgba(0, 0, 0, 0.5) !important;
	background: rgba(0, 0, 0, 0.1) !important;
	/* background-color: var(--color-orange-faded) !important; */
}

.popoverContent {
	max-width: 180px;

	overflow: hidden;
}

.popoverText {
	width: 100%;

	text-align: left;

	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media screen and (min-width: 450px) {
	.headerTitle {
		max-width: unset;
	}

	.popoverContent {
		max-width: 240px;
	}
}
